import { template as template_84a18ee3aa0e4aea8ecdec3c0e4463e8 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_84a18ee3aa0e4aea8ecdec3c0e4463e8(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
